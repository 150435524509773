<template>
  <v-container>
    <app-general-accounting-statement
      apiEndPoint="/accounting/account/statement/"
    ></app-general-accounting-statement>
  </v-container>
</template>

<script>
import generalAccountingStatement from "./generalAccountingStatement.vue";
export default {
  components: {
    appGeneralAccountingStatement: generalAccountingStatement,
  },
};
</script>

<style>
</style>